@import '~antd/dist/antd.css';
@import './fonts.scss';
@import './variables.scss';

/* rest of app styles */
body {
  font-family: $avenir-font;
  background: #f9fafb;
}

.app.header img {
  cursor: pointer;
}

// .ant-steps-item-finish
//   > .ant-steps-item-container
//   > .ant-steps-item-content
//   > .ant-steps-item-title::after {
//   border: 1px dashed #bacddc;
// }
//
// .ant-steps-item
//   > .ant-steps-item-container
//   > .ant-steps-item-content
//   > .ant-steps-item-title::after {
//   border: 1px dashed #bacddc;
// }
