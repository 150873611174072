@import '../../styles/variables';

.success-icon-wrapper {
  text-align: center;
  margin: 100px 0px 100px 0px;

  p {
    font-family: $avenir-font;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 200%;
    text-align: center;
    color: $black-color;
    padding-top: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .mobile-wrapper {
    max-width: 90%;
  }
}
