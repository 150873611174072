@import '../../styles/variables';

.title {
  color: $primary-color !important;
  font-family: $avenir-font;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 49px;
  text-align: center;
  padding-top: 20px;
}
