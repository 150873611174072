@import '../../../../../../styles/variables';

.payment-choice-extend {
  .spacing {
    margin: 20px 0px 20px 0px;
  }

  .title {
    font-family: $avenir-font;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: $primary-color;
  }
  .main-icons {
    width: 96px;
    height: 96px;
    margin: 1rem;
    cursor: pointer;

    .ant-card {
      padding-bottom: 7% !important;
    }
  }

  .amount {
    font-family: Avenir;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 200%;
    text-align: center;
    color: #454f5a;
  }

  .payment {
    font-family: Avenir;
    font-style: normal;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    color: #454f5a;
  }
  .align-payment-center {
    text-align: center;
  }

  .payment-installment-header {
    font-weight: 900 !important;
    text-align: center;
  }

  .installment-wrapper {
    margin-left: 10%;
  }

  .installment-table {
    padding: 10px;
    width: 100%;
  }

  .no-installment {
    color: $primary-color;
    font-size: 22px;
    font-weight: 500;
  }
}
