$screen-xs: 480px;
$screen-sm: 576px;
$screen-md: 768px;
$screen-lg: 992px;
$screen-xl: 1200px;
$screen-xxl: 1600px;

// @mixin xs {
//   @media only screen (max-width: #{$screen-xs}) {
//     @content;
//   }
// }
@mixin sm {
  @media (max-width: #{$screen-sm}) {
    @content;
  }
}
@mixin md {
  @media (min-width: #{$screen-md}) {
    @content;
  }
}
@mixin lg {
  @media (min-width: #{$screen-lg}) {
    @content;
  }
}
@mixin xl {
  @media (min-width: #{$screen-xl}) {
    @content;
  }
}
@mixin xxl {
  @media (min-width: #{$screen-xxl}) {
    @content;
  }
}

// @mixin md {
//    @media (min-width: #{$screen-md-min}) {
//        @content;
//    }
// }
//
// // Large devices
// @mixin lg {
//    @media (min-width: #{$screen-lg-min}) {
//        @content;
//    }
// }
//
// // Extra large devices
// @mixin xl {
//    @media (min-width: #{$screen-xl-min}) {
//        @content;
//    }
// }

// @include sm {
//     padding: 0 20px;
// }

//
// // Small tablets and large smartphones (landscape view)
// $screen-sm-min: 576px;
//
// // Small tablets (portrait view)
// $screen-md-min: 768px;
//
// // Tablets and small desktops
// $screen-lg-min: 992px;
//
// // Large tablets and desktops
// $screen-xl-min: 1200px;
